<template>

    <div class="h-full w-full overflow-hidden relative flex flex-col ">
      
        <div id="header" class="bg-gradient-module px-2 mb-2 h-16 flex-none flex flex-row items-center">

            <router-link v-if="userData.screen != 3" :to="{ name: 'home' }">
                <img src="@/assets/images/logoEy_old.svg" class="mb-2 mt-2 h-14">
            </router-link>

            <router-link :to="{ name: 'home' }">
                <span class="font-semibold ml-2">Executive Dashboard</span>
            </router-link>
            
            <div class="ml-auto mr-8 relative flex items-center">
                <ExcelDownloadButton />
            </div>

            <separator direction="vertical"/>

            <div class=" flex items-center ml-8" v-if="userData && globalParamsPretty.rol">

                <div class="text-right mr-4">
                    <div>{{ userData.user_name }}</div>
                    <div class="bg-violet rounded text-xs px-3 py-2px mt-1">
                        {{ globalParamsPretty.rol.role_type_name }}
                        <template v-if="globalParamsPretty.rol.role_type_link !== 'presidencia'">
                            / {{ globalParamsPretty.rol.gui_role_name }}
                        </template>
                    </div>
                </div>

                <img 
                    v-show="profilePhoto"
                    class="h-12 w-12 mr-4 rounded-full bg-cover bg-center shadow-xl" 
                    :src="profilePhoto"
                    @error="imageError"
                />

            </div>

            <div v-if="userData">
                <i class="mdi mdi-power text-2xl hover:text-aux" @click="logOut()"></i>
            </div>

        </div>

        <div class="module p-0 mx-2 flex items-center">

            <div class="p-2 font-thin text-font-aux capitalize flex items-center flex-none">
                <template v-for="(crumb, i)  in crumbs">
                    <router-link 
                        :key="i"
                        class="header-btn" 
                        :to="{ name: 'home' }"
                        v-if="crumb.to === 'desktop'"
                    >
                        <i class="mdi mdi-chevron-double-right px-2" v-if="i"></i>
                        <span v-html="crumb.name"></span>
                    </router-link>

                    <template v-else>
                        <div :key="i">
                            <i class="mdi mdi-chevron-double-right px-2" v-if="i"></i>
                            <span v-html="crumb.name"></span>
                        </div>
                    </template>
                </template>
            </div>

            <template v-if="globalParamsPretty.time">
                <separator direction="vertical"/>
                <div class="p-2 flex-none">
                    <span class="text-font-gray">Period:</span>
                    From {{ globalParamsPretty.time.From | moment('DD MMM YYYY') }} to {{ globalParamsPretty.time.To | moment('DD MMM YYYY') }}
                </div>
            </template>

            <template v-if="globalParamsPretty.hierarchy" v-for="el, k, i in globalParamsPretty.hierarchy">

                <separator direction="vertical"/>
                
                <div class="p-2 ellipsis">
                    <span v-if="k!=='sl_zeus'" class="text-font-gray uppercase">{{ getName(k) }}:</span>
                    <span v-else class="text-font-gray uppercase">SL:</span>
                    {{ el.join(',') }}
                </div>
            
            </template>

            <template v-if="globalParamsPretty.period">
                <separator direction="vertical"/>
                <div class="p-2 ellipsis">
                    <span class="text-font-gray">Current Project Management Data:</span>
                    {{ globalParamsPretty.period.name}}
                </div>
            </template>

            <div v-if="userData.screen === 1 || userData.screen === 3"
                 class="cursor-pointer w-12 ml-auto mr-4 flex flex-col justify-center
                items-center text-font-aux header-btn"
                 @click="goCMS()">
                <span class="text-xs">CMS</span>
            </div>

        </div>

        <div class="flex-1 overflow-hidden relative m-2 rounded-lg">

            <transition
                    name="fade"
                    enter-active-class="animated fadeIn"
                    leave-active-class="animated fadeOut"
                    mode="out-in">
                <router-view v-if="globalParams" style="animation-duration: .3s"/>
            </transition>

            <div id="filters"
                 v-click-outside="closeFilters"
                 class="absolute top-0 h-full w-122 bg-filters shadow rounded-r-lg
                 transition duration-500 ease-in-out  flex flex-col z-50"
                 :style="(openedFilters) ? 'transform:translateX(0%);' : 'transform:translateX(-100%);'"
                 v-if="userData">
                <div class="module-title text-4xl p-4">Filters</div>
                <filters @closeFilters="closeFilters"/>
                
                <div class="open-filters-btn header-btn
                    rounded-r-full shadow-lg h-16 w-8 flex flex-col items-center justify-center cursor-pointer"
                     @click="openFilters()">
                    <i class="mdi mdi-dots-vertical text-aux text-3xl"></i>
                </div>
            </div>

            <comments-bar v-if="globalParams && path !== 'PersonalDashboard'"/>

        </div>

    </div>

</template>

<script>

    const Filters = () => import('@/components/Globals/Filters')
    const CommentsBar = () => import('@/components/Desktop/CommentsBar')
    const ExcelDownloadButton = () => import('@/components/Desktop/ExcelDownloadButton');
    import {actions, state} from '@/store'
    import ClickOutside from 'vue-click-outside'

    export default {
        components: {
            Filters,
            CommentsBar,
            ExcelDownloadButton
        },
        data() {
            return {
                openedFilters: true,
                openedComments: false,
                loading: true,
                counter: 30,
                defaultImg: process.env.VUE_APP_AUTH_IMG_USER + 'PersonPlaceholder.96x96x32.png',
            }
        },
        computed: {
            imageProfile() { 
                return process.env.VUE_APP_IMG_PROFILE 
            },
            userProfile() { 
                return process.env.VUE_APP_IMG_USER 
            },
            userData() { 
                return state.user 
            },
            selectedRol() { 
                return state.selectedRol 
            },
            globalParams() { 
                return state.globalParams 
            },
            globalParamsPretty(){ 
                return state.globalParamsPretty 
            },
            profilePhoto() {
                return process.env.VUE_APP_AUTH_IMG_USER + state.user.profile_photo;
            },
            defaultPhoto() {
                return process.env.VUE_APP_AUTH_IMG_USER + 'PersonPlaceholder.96x96x32.png'
            },
            crumbs() {
                let crumbs = []
                for (let route in this.$route.matched) {
                    let name = this.$route.matched[route].meta.breadCrumb
                        ? this.$route.matched[route].meta.breadCrumb
                        : this.$route.matched[route].name
                    crumbs.push({
                        name: name,
                        to: this.$route.matched[route].name
                    })
                }
                if (Object.keys(this.$route.params).length) {
                    for (let param in this.$route.params) {
                        if (param !== 'idSL') {
                            crumbs.push({name: this.$route.params[param]})
                        }
                    }
                }
                return crumbs
            },
            path(){ 
                return this.$route.path.split('/')[2] 
            },
        },
        methods: {
            imageError(event) {
                event.target.src = this.defaultPhoto;
            },
            logOut() {
                this.axios.post(process.env.VUE_APP_BACKEY_URL + 'passport/logout'
                ).then(response => {
                    actions.setToken(null)
                    actions.setUser(null)
                    actions.resetAuth()
                    this.$router.push({name: 'login'})
                }).catch( error => {
                })

            },
            openFilters(){ 
                this.openedFilters = !this.openedFilters 
            },
            closeFilters(){ 
                this.openedFilters = false
            },
            goCMS(){ 
                location.href = process.env.VUE_APP_CMS 
            },
            getName(option){
                
                if(option == 'competencyIdWithRegionId'){
                    return 'SMU'
                } else {
                    return option
                }
            },
            oldtoken(){
                return state.oldtoken
            }
        },
        watch: {
            globalParams() {
                this.closeFilters()
            }

        },
        directives: {ClickOutside},
        mounted(){
            if(state.user.screen == 3){
                this.$router.push( {name: 'PersonalDashboard'} )
            }
        }
    }
</script>